// import GraverMaganer from './components/GraverManager';
// import GraverFontsPopup from './components/GraverFontsPopup';
//
// $(function () {
//     const graverManager = new GraverMaganer();
//     const graverFontsPopup = new GraverFontsPopup();
//
//     $('[id*="fonts-graver"].modal').on('shown.bs.modal', function () {
//       if (!prestashop.responsive.mobile) {
//         $('.zoomple').zoomple({
//           source: 'rel',
//           blankURL: '../themes/starter/assets/img/blank.gif',
//           loaderURL: '../themes/starter/assets/img/loader.gif',
//           bgColor : '#fff',
//           offset : {x:-250,y:-250},
//           zoomWidth : 500,
//           zoomHeight : 500,
//           roundedCorners : true
//         });
//       }
//     });
// });

$(document).ready(function () {
  if (
    $('.quickview').length === 0
    && $('#product_customization_id').val() === '0'
    && $('.js-customization-radio').length
  ) {
    sendRequest($('#add-to-cart-or-refresh'));
  }

  $(document).on('show.bs.modal', '.quickview', function() {
    if ($(this).find('#product_customization_id').val() === '0' && $(this).find('.js-customization-radio').length) {
      sendRequest($(this).find('#add-to-cart-or-refresh'));
    }
  });

  $(document).on('change', '.js-customization-radio', function () {
    sendRequest($(this).parents('form'));
  })
});

function sendRequest($form) {
  let container = $form.find('#js-customization-options-container');
  let productCustomizationIdInput = $form.find('#product_customization_id');
  $.ajax({
    url: container.data('action'),
    method: 'post',
    data: $form.serialize(),
    dataType: 'json',
    success: function (response) {
      if (response.success) {
        productCustomizationIdInput.val(response.id_customization);
      } else {
        alert(response.error);
      }
    }

  });
}
