$(document).ready(function () {
  $(document).on('submit', '#product-question-form', function (e) {
    if (typeof validateAgreements === 'function') {
      validateAgreements(e, submit);
    } else {
      e.preventDefault();
      submit();
    }
  });
});

function submit() {
  let form = $('#product-question-form');

  $.ajax({
    url: form.attr('action'),
    method: 'post',
    data: form.serialize(),
    dataType: 'json',
    success: function (response) {
      if(response.success) {
        alert(response.message);
      } else {
        alert(response.errors);
      }
    }
  });
}
