/* global $, prestashop */
$(document).ready(function () {
  prestashop.on('updateCart', function (e) {
    if(!e.reason.doNotRefresh) {
      var div = $('#unavailable_shipping');
      var form = div.find('form');

      $.ajax({
        type: 'POST',
        url: div.data('refresh-url'),
        data: form.length ? form.serialize() : null,
        dataType: 'json',
        async: true,
        success: function (response) {
          if(response.success) {
            div.replaceWith(response.html);
          }
        }
      });
    }
  });

  $(document).on('change', '.js-shipping-option', function (e) {
    var div = $('#unavailable_shipping');
    var form = div.find('form');

    if(form.length) {
      e.preventDefault();
      $.ajax({
        url: form.attr('action'),
        method: 'POST',
        data: form.serialize(),
        dataType: 'json',
        success: function (response) {
          if(response.success) {
            prestashop.emit(
              'updateCart', {
                reason: {
                  cart: prestashop.cart,
                  doNotRefresh: !response.refresh
                }
            });
          } else {
            alert(response.error);
          }
        }
      })
    }
  });
});
