import './components/main';

$(document).ready(function () {
  $(document).on('click', '.js-video-show', function (e) {
    e.preventDefault();

    var $row = $('.js-video-row[data-row="' + $(this).data('target-row') + '"]');
    if (!$row.hasClass('d-none')) {
      $row.find('video').get(0).pause();
    }

    $row.toggleClass('d-none');
  });
});
